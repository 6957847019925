@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700');

body,
div,
dl,
dt,
dd,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: normal;
}

caption,
th {
  text-align: left;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

.wrapper {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

body {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #333;
}

.info_header {
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  position: fixed;
  z-index: 100000;
  border-bottom: 1px solid #e5017c;
  box-shadow: 0px 7px 5px 0px rgba(50, 50, 50, 0.2);
}

.info_header .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_header .container .navigation_container {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.info_header .container .navigation_container .logo {
  padding: 5px 0;
}

.info_header .container .navigation_container .navigation {
  display: flex;
}

.info_header .container .navigation_container .navigation ul.nav {
  display: inline-flex;
  padding: 0;
  margin: 0;
}

.info_header .container .navigation_container .navigation ul.nav li {
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 0 5px;
}

.info_header .container .navigation_container .navigation ul.nav li a {
  text-decoration: none;
  color: #4a4a4a;
  letter-spacing: 0.8px;
  font-size: 16px;
  padding: 33px 5px 38px 5px;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  transition: all 0.2s ease-in-out;
  outline: none;
  font-weight: 500;
}

.info_header .container .navigation_container .navigation ul.nav li a:hover {
  color: #e5017c;
}

.info_header .container .navigation_container .navigation ul.nav .active {
  border-bottom: 2px solid #e5017c;
}

.info_header .container .navigation_container .navigation ul.nav .active a {
  color: #e5017c;
}

.info_header .container .navigation_height .logo a img {
  width: 80%;
  height: auto;
}

.info_header .navbar-header {
  display: none;
  justify-content: space-between;
  align-items: center;
  z-index: 1002;
}

.info_header .navbar-header .logo {
  padding: 5px 0;
}

.info_header .navbar-header .logo a {
  text-decoration: none;
}

.info_header .navbar-header .logo a img {
  width: 100px;
  height: auto;
}

.info_header .navbar-header .menu-trigger,
.info_header .navbar-header .menu-trigger span {
  transition: all .4s;
  box-sizing: border-box;
  z-index: 1002;
}

.info_header .navbar-header .menu-trigger {
  position: relative;
  width: 40px;
  height: 25px;
}

.info_header .navbar-header .menu-trigger span {
  position: absolute;
  right: 0;
  width: 100%;
  height: 4px;
  background-color: #333;
  border-radius: 4px;
}

.info_header .navbar-header .menu-trigger span:nth-of-type(1) {
  top: 0;
}

.info_header .navbar-header .menu-trigger span:nth-of-type(2) {
  top: 10px;
}

.info_header .navbar-header .menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

.info_header #menu02.active span:nth-of-type(1) {
  transform: translateY(10px) rotate(-315deg);
}

.info_header #menu02.active span:nth-of-type(2) {
  opacity: 0;
}

.info_header #menu02.active span:nth-of-type(3) {
  transform: translateY(-12px) rotate(315deg);
}

.info_header .burger-menu {
  display: flex;
  background: #fff;
  width: 100%;
  display: none;
  box-shadow: 5px 3px 5px rgba(191, 191, 191, 0.7);
  z-index: 1001;
  height: 260px;
  overflow: auto;
  content: "";
}

.info_header .burger-menu .navi .nav {
  margin-left: 0;
  padding-left: 0;
}

.info_header .burger-menu .navi .nav li {
  list-style: none;
  padding: 15px 0;
  transition: all ease-out 0.2s;
  border-bottom: 1px solid #f5f5f5;
  overflow: hidden;
  text-align: center;
}

.info_header .burger-menu .navi .nav li a {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  color: #333;
}

.info_header .burger-menu .navi .nav li:last-child {
  border-bottom: none;
}

.info_block_video {
  background-position: center center;
  background-size: cover;
  position: relative;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  line-height: 0;
}

.info_block_video #video-bg video {
  width: 100%;
  max-height: 100%;
}

.info_block_video .container {
  position: absolute;
  content: "";
  top: 23%;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  text-align: center;
}

.info_block_video .container .titles {
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info_block_video .container .titles .photo_logo {
  width: 35%;
}

.info_block_video .container .titles .photo_logo .name {
  color: #fff;
  text-transform: uppercase;
  padding: 30px 0;
  font-size: 16px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.8);
  font-weight: 700;
  line-height: 1px;
  margin-left: -50px;
}

.info_block_video .container .titles .photo_logo img:nth-child(1) {
  width: 250px;
  margin-left: -50px;
}

.info_block_video .container .titles .photo_logo img:nth-child(3) {
  width: 160px;
  margin-left: -50px;
}

.info_block_video .container .titles .photo_logo .text h4 {
  color: #ce005d;
  text-align: center;
  margin-left: -50px;
  padding-top: 15px;
  font-size: 23px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.8);
  line-height: 28px;
  margin-bottom: 10px;
}

.info_block_video .container .titles .photo_logo .text p {
  color: #fff;
  text-transform: uppercase;
  padding: 3pxpx 0 3px 0;
  font-size: 15px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.8);
  font-weight: 700;
  line-height: 22px;
  margin-left: -50px;
}

.info_block_video .container .titles .photo_water {
  width: 35%;
}

.info_block_video .container .titles .photo_water img {
  width: 80%;
}

.info_block_header_mob {
  display: none;
  padding-top: 80px;
}

.info_block_header_mob .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  align-items: center;
}

.info_block_header_mob .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  text-align: center;
}

.info_block_header_mob .container .block .name {
  color: #929292;
  text-transform: uppercase;
  padding: 0 0 30px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.info_block_header_mob .container .block img:nth-child(2) {
  width: 150px;
}

.info_block_header_mob .container .block .bottle {
  width: 80%;
  padding-bottom: 30px;
}

.info_block_header_mob .container .block .text h4 {
  color: #ce005d;
  text-align: center;
  font-size: 23px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
  line-height: 28px;
  margin-bottom: 10px;
}

.info_block_header_mob .container .block .text p {
  color: #929292;
  text-transform: uppercase;
  padding: 3pxpx 0 3px 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
}

.info_gift_nature {
  position: relative;
}

.info_gift_nature:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background: #333 url("../img/promo-date-bg.png") repeat;
  bottom: -5px;
  right: 0;
  left: 0;
  z-index: -1;
}

.info_gift_nature .photo img {
  width: 100%;
}

.info_gift_nature .block {
  position: absolute;
  bottom: 20%;
  right: 15%;
}

.info_gift_nature .block h2 {
  text-transform: uppercase;
  font-style: italic;
  color: #adadad;
  letter-spacing: 1px;
  font-size: 30px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}

.info_natural_mineral_composition {
  padding-bottom: 40px;
  position: relative;
}

.info_natural_mineral_composition:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background: #333 url("../img/promo-date-bg.png") repeat;
  bottom: -5px;
  right: 0;
  left: 0;
  z-index: -1;
}

.info_natural_mineral_composition:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background: #333 url("../img/promo-date-bg.png") repeat;
  top: -3px;
  right: 0;
  left: 0;
  z-index: -1;
}

.info_natural_mineral_composition .caption {
  padding-top: 50px;
  padding-bottom: 70px;
}

.info_natural_mineral_composition .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 25px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #adadad;
  letter-spacing: 0.5px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.info_natural_mineral_composition .caption h2 span {
  color: #e5017c;
}

.info_natural_mineral_composition .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_natural_mineral_composition .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(41.66666666666667% - 30px);
}

.info_natural_mineral_composition .container .block .table {
  border: 1px solid #ddd;
  width: 100%;
  color: #929292;
}

.info_natural_mineral_composition .container .block .table tbody:nth-child(1) tr th {
  padding: 5px 0;
  text-align: center;
}

.info_natural_mineral_composition .container .block .table tbody:nth-child(1) tr th strong {
  font-weight: 700;
  font-size: 16px;
}

.info_natural_mineral_composition .container .block .table tbody tr {
  border-bottom: 1px solid #ddd;
}

.info_natural_mineral_composition .container .block .table tbody tr td {
  border-right: 1px solid #ddd;
  padding: 8px;
  font-size: 14px;
}

.info_natural_mineral_composition .container .block p {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 10px;
  color: #929292;
}

.info_natural_mineral_composition .container .block .img {
  text-align: center;
  padding-top: 15px;
}

.info_natural_mineral_composition .container .block .img img {
  margin: 0 7px;
}

.info_natural_mineral_composition .container .block_img {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.info_natural_mineral_composition .container .block_img img:nth-child(1) {
  padding-bottom: 30px;
  width: 200px;
}

.info_natural_mineral_composition .container .block_img img:nth-child(2) {
  margin-top: -25px;
  border-radius: 5%;
}

.info_why_jevea {
  background: #fafafa;
  padding: 40px 0 50px 0;
  position: relative;
  overflow: hidden;
}

.info_why_jevea:before {
  position: absolute;
  content: "";
  background: url("../img/waterPNG50141.png") no-repeat;
  width: 100%;
  height: 236px;
  top: 0;
  right: 0;
  transform: rotate(180deg);
  opacity: 0.5;
}

.info_why_jevea:after {
  position: absolute;
  content: "";
  background: url("../img/water_PNG3309.png") no-repeat;
  width: 700px;
  height: 665px;
  top: -50px;
  left: 0;
  opacity: 0.5;
}

.info_why_jevea .caption {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
}

.info_why_jevea .caption h2 {
  text-transform: uppercase;
  color: #adadad;
  letter-spacing: 1px;
  font-size: 38px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.info_why_jevea .caption img {
  width: 180px;
}

.info_why_jevea .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  z-index: 1000;
  position: relative;
}

.info_why_jevea .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(50% - 30px);
  text-align: center;
}

.info_why_jevea .container .block .photo img {
  width: 95%;
}

.info_why_jevea .container .block ul {
  list-style: none;
}

.info_why_jevea .container .block ul li {
  display: flex;
  padding: 10px 0 14px 0;
  font-size: 19px;
  line-height: 26px;
}

.info_why_jevea .container .block ul li span {
  padding-right: 5px;
  color: #929292;
  font-weight: 700;
}

.info_why_jevea .container .block ul li p {
  color: #929292;
  display: flex;
  align-items: center;
}

.info_why_jevea .container .block ul li p img {
  width: 120px;
  padding-left: 10px;
}

.info_why_jevea .container .block ul li:nth-child(1) {
  padding-top: 0;
}

.info_why_jevea .container .block:nth-child(2) {
  text-align: inherit;
}

.info_jevea_signature_bottle {
  padding: 40px 0 50px 0;
  position: relative;
}

.info_jevea_signature_bottle:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background: #333 url("../img/promo-date-bg.png") repeat;
  bottom: -5px;
  right: 0;
  left: 0;
  z-index: -1;
}

.info_jevea_signature_bottle .caption {
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
}

.info_jevea_signature_bottle .caption h2 {
  text-transform: uppercase;
  color: #adadad;
  letter-spacing: 1px;
  font-size: 38px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.info_jevea_signature_bottle .caption img {
  width: 180px;
}

.info_jevea_signature_bottle .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info_jevea_signature_bottle .container .block p {
  color: #929292;
  font-size: 18px;
  padding-bottom: 25px;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.info_jevea_signature_bottle .container .block p span {
  padding-right: 5px;
  font-weight: 700;
  color: #e5017c;
}

.info_jevea_signature_bottle .container .block:nth-child(1) {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.info_jevea_signature_bottle .container .block:nth-child(2) {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.info_jevea_signature_bottle .container .block:nth-child(2) .photo img {
  width: 100%;
}

.info_jevea_signature_bottle .container .block:nth-child(3) {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info_instagram {
  padding: 40px 0 60px 0;
  background: #fafafa;
  position: relative;
}

.info_instagram:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background: #333 url("../img/promo-date-bg.png") repeat;
  bottom: -6px;
  right: 0;
  left: 0;
  z-index: -1;
}

.info_instagram:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background: #333 url("../img/promo-date-bg.png") repeat;
  top: -5px;
  right: 0;
  left: 0;
  z-index: -1;
}

.info_instagram .caption {
  padding-bottom: 40px;
  text-align: center;
}

.info_instagram .caption h2 {
  text-transform: uppercase;
  color: #e5017c;
  letter-spacing: 1px;
  font-size: 38px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.info_instagram .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info_instagram .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.info_slogn {
  position: relative;
}

.info_slogn:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background: #333 url("../img/promo-date-bg.png") repeat;
  bottom: -5px;
  right: 0;
  left: 0;
  z-index: -1;
}

.info_slogn .photo img {
  width: 1000px;
}

.info_slogn .block {
  position: absolute;
  bottom: 20%;
  right: 10%;
}

.info_slogn .block h2 {
  text-transform: uppercase;
  font-style: italic;
  color: #adadad;
  letter-spacing: 1px;
  font-size: 30px;
  line-height: 42px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  padding: 0 30px;
}

.info_jevea_benefits {
  padding: 40px 0 50px 0;
  background: #fafafa;
  position: relative;
}

.info_jevea_benefits:before {
  position: absolute;
  content: "";
  background: url("../img/water_PNG3317.png") no-repeat;
  width: 400px;
  height: 450px;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.info_jevea_benefits:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background: #333 url("../img/promo-date-bg.png") repeat;
  bottom: -7px;
  right: 0;
  left: 0;
  z-index: -1;
}

.info_jevea_benefits .caption {
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
}

.info_jevea_benefits .caption h2 {
  text-transform: uppercase;
  color: #adadad;
  letter-spacing: 1px;
  font-size: 38px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.info_jevea_benefits .caption img {
  width: 180px;
}

.info_jevea_benefits .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info_jevea_benefits .container .block p {
  color: #929292;
  font-size: 18px;
  padding-bottom: 15px;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.info_jevea_benefits .container .block p span {
  padding-right: 5px;
  font-weight: 700;
  color: #e5017c;
}

.info_jevea_benefits .container .block:nth-child(1) {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.info_jevea_benefits .container .block:nth-child(2) {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.info_jevea_benefits .container .block:nth-child(2) .photo {
  padding-top: 100px;
}

.info_jevea_benefits .container .block:nth-child(2) .photo img {
  width: 100%;
}

.info_jevea_benefits .container .block:nth-child(3) {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.info_time_honored {
  padding: 60px 0 75px 0;
  position: relative;
}

.info_time_honored:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background: #333 url("../img/promo-date-bg.png") repeat;
  bottom: -5px;
  right: 0;
  left: 0;
  z-index: -1;
}

.info_time_honored .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_time_honored .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(66.66666666666667% - 30px);
}

.info_time_honored .container .block .text {
  text-align: center;
}

.info_time_honored .container .block .text h2 {
  text-transform: uppercase;
  color: #adadad;
  letter-spacing: 1px;
  font-size: 38px;
  line-height: 46px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  padding: 0 30px;
}

.info_time_honored .container .block .text img {
  width: 200px;
  padding-bottom: 5px;
}

.info_sourse {
  padding: 40px 0 50px 0;
  background: #fafafa;
  position: relative;
}

.info_sourse:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background: #333 url("../img/promo-date-bg.png") repeat;
  bottom: -5px;
  right: 0;
  left: 0;
  z-index: -1;
}

.info_sourse .caption {
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
}

.info_sourse .caption h2 {
  text-transform: uppercase;
  color: #adadad;
  letter-spacing: 1px;
  font-size: 38px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.info_sourse .caption img {
  width: 180px;
}

.info_sourse .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info_sourse .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(50% - 30px);
}

.info_sourse .container .block p {
  color: #929292;
  font-size: 18px;
  padding-bottom: 25px;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.info_sourse .container .block .photo_left {
  padding-top: 30px;
}

.info_sourse .container .block .photo_right {
  padding-bottom: 20px;
}

.info_sourse .container .block .flag {
  padding-bottom: 20px;
  text-align: center;
}

.info_sourse .container .block .flag img {
  width: 200px;
}

.info_products {
  padding: 40px 0 60px 0;
  position: relative;
  overflow: hidden;
}

.info_products:after {
  position: absolute;
  content: "";
  background: url("../img/water_PNG3317.png") no-repeat;
  width: 800px;
  height: 550px;
  bottom: -200px;
  right: -100px;
  opacity: 0.2;
  z-index: -1;
  transform: rotate(-50deg);
}

.info_products:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background: #333 url("../img/promo-date-bg.png") repeat;
  bottom: -5px;
  right: 0;
  left: 0;
  z-index: -1;
}

.info_products .caption .title {
  display: flex;
  justify-content: center;
}

.info_products .caption .title h2 {
  text-transform: uppercase;
  color: #adadad;
  letter-spacing: 1px;
  font-size: 38px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.info_products .caption .title img {
  width: 180px;
}

.info_products .caption h3 {
  text-transform: uppercase;
  color: #adadad;
  letter-spacing: 1px;
  font-size: 17px;
  line-height: 25px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  padding: 10px 30px 0 30px;
  text-align: center;
}

.info_products .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 25px;
}

.info_products .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(50% - 30px);
}

.info_products .container .block .text {
  text-align: center;
}

.info_products .container .block .text h2 {
  padding-top: 20px;
  text-transform: uppercase;
}

.info_products .container .block .text .liter {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.info_products .container .block .text .liter p {
  padding: 0 60px;
  color: #929292;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.info_products .container .block:nth-child(1) .text h2 {
  color: #e5017c;
}

.info_products .container .block:nth-child(2) .text h2 {
  color: #542ba0;
}

.info_premium_choice {
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;
  background: #fafafa;
}

.info_premium_choice:before {
  position: absolute;
  content: "";
  background: url("../img/water_PNG3317.png") no-repeat;
  width: 800px;
  height: 550px;
  top: -200px;
  right: -100px;
  opacity: 0.3;
  z-index: 1;
  transform: rotate(-50deg);
}

.info_premium_choice:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background: #333 url("../img/promo-date-bg.png") repeat;
  top: -3px;
  right: 0;
  left: 0;
  z-index: -1;
}

.info_premium_choice .caption {
  padding-top: 70px;
  padding-bottom: 100px;
}

.info_premium_choice .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 38px;
  line-height: 50px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #adadad;
  letter-spacing: 0.5px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.info_premium_choice .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: space-around;
  align-items: center;
}

.info_premium_choice .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(41.66666666666667% - 30px);
}

.info_premium_choice .container .block:nth-child(1) .photo img {
  border-radius: 10px;
  opacity: 0.8;
}

.info_premium_choice .container .block:nth-child(2) .photo {
  padding-top: 30px;
}

.info_awards_winning {
  padding: 40px 0 50px 0;
  position: relative;
}

.info_awards_winning:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background: #333 url("../img/promo-date-bg.png") repeat;
  top: -3px;
  right: 0;
  left: 0;
  z-index: -1;
}

.info_awards_winning .caption {
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
}

.info_awards_winning .caption h2 {
  text-transform: uppercase;
  color: #adadad;
  letter-spacing: 1px;
  font-size: 38px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.info_awards_winning .caption img {
  width: 180px;
}

.info_awards_winning .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info_awards_winning .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(50% - 30px);
}

.info_awards_winning .container .block .photo {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.info_awards_winning .container .block .photo .bottle {
  width: 45%;
}

.info_awards_winning .container .block .photo .medal {
  width: 45%;
  text-align: center;
}

.info_awards_winning .container .block .photo .medal p {
  color: #929292;
  font-size: 18px;
  padding-bottom: 7px;
  line-height: 25px;
  letter-spacing: 0.5px;
  font-weight: 700;
  padding-top: 3px;
}

.info_awards_winning .container .block .photo .medal p span {
  color: #ffbb33;
  padding-right: 5px;
}

.info_awards_winning .container .block .photo .kremlin {
  width: 45%;
}

.info_awards_winning .container .block .photo .kremlin img {
  border-radius: 10px;
}

.info_awards_winning .container .block .text p {
  color: #929292;
  font-size: 18px;
  padding-bottom: 25px;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.info_awards_winning .container .block .text p span {
  color: #e5017c;
}

.info_maps {
  background: #fafafa;
  padding: 40px 0 50px 0;
  position: relative;
}

.info_maps:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background: #333 url("../img/promo-date-bg.png") repeat;
  top: -5px;
  right: 0;
  left: 0;
  z-index: -1;
}

.info_production {
  padding: 40px 0 50px 0;
  position: relative;
}

.info_production:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background: #333 url("../img/promo-date-bg.png") repeat;
  bottom: -5px;
  right: 0;
  left: 0;
  z-index: -1;
}

.info_production .caption {
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
}

.info_production .caption h2 {
  text-transform: uppercase;
  color: #adadad;
  letter-spacing: 1px;
  font-size: 38px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.info_production .caption img {
  width: 180px;
}

.info_production .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info_production .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(50% - 30px);
}

.info_production .container .block p {
  color: #929292;
  font-size: 18px;
  padding-bottom: 25px;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.info_production .container .block .photo_left {
  padding-top: 20px;
}

.info_production .container .block .photo_left img {
  border-radius: 10px;
}

.info_production .container .block .photo_right {
  padding-bottom: 20px;
}

.info_production .container .block .photo_right img {
  border-radius: 10px;
}

.info_footer {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 120px;
  background: url("../img/cobweb-3725540_1920.jpg") center no-repeat;
  background-attachment: scroll;
  background-size: auto;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

.info_footer:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  opacity: 1;
}

.info_footer .caption {
  position: relative;
  padding-bottom: 10px;
  padding-top: 40px;
  z-index: 100;
}

.info_footer .caption h3 {
  font-family: 'Playfair Display', serif;
  font-size: 32px;
  line-height: 43px;
  letter-spacing: 0.3px;
  position: relative;
  color: #fff;
}

.info_footer .caption h3:before {
  content: '';
  width: 25px;
  height: 1px;
  vertical-align: middle;
  background: #fff;
  display: inline-block;
  margin-right: 10px;
}

.info_footer .caption p {
  font-size: 17px;
  color: #fff;
  font-family: 'Playfair Display', serif;
  line-height: 24px;
  padding-top: 25px;
}

.info_footer .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  z-index: 100;
}

.info_footer .container .block_form {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(50% - 30px);
  margin-top: 20px;
}

.info_footer .container .block_form .form h2 {
  color: #c2af77;
  font-variant: small-caps;
  font-size: 22px;
  letter-spacing: 1.5px;
  font-weight: 700;
  padding-bottom: 20px;
  line-height: 30px;
}

.info_footer .container .block_form .form #feedback-form input[type="text"],
.info_footer .container .block_form .form #feedback-form [type="email"],
.info_footer .container .block_form .form #feedback-form [type="tel"],
.info_footer .container .block_form .form #feedback-form textarea {
  width: 100%;
  padding: 10px 20px;
  border: 2px solid #dedede;
  margin-bottom: 20px;
  outline: none;
  transition: all 0.2s ease-in-out;
  color: #000;
  font-size: 13px;
  box-sizing: border-box;
  resize: vertical;
  background: #dcdddf;
  font-family: 'Playfair Display', serif;
  border-radius: 0.3rem;
  opacity: 0.7;
}

.info_footer .container .block_form .form #feedback-form .input {
  display: flex;
}

.info_footer .container .block_form .form #feedback-form .input input:nth-child(2) {
  margin-right: 0;
}

.info_footer .container .block_form .form #feedback-form .input .inp {
  padding: 0 10px 5px 5px;
}

.info_footer .container .block_form .form #feedback-form .input .inp:nth-child(1) {
  padding-left: 0;
}

.info_footer .container .block_form .form #feedback-form .input .inp:nth-child(2) {
  padding-right: 0;
}

.info_footer .container .block_form .form #feedback-form input[type="text"]:focus,
.info_footer .container .block_form .form #feedback-form textarea:focus,
.info_footer .container .block_form .form #feedback-form [type="email"]:focus {
  border: 2px solid #c20069;
  box-shadow: 0 5px 25px rgba(229, 1, 124, 0.5);
  opacity: 1;
}

.info_footer .container .block_form .form #feedback-form textarea {
  height: 146px;
}

.info_footer .container .block_form .form #feedback-form input[type="submit"] {
  padding: 10px 35px 11px 35px;
  outline: none;
  border: none;
  background: #fff;
  color: #333;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border-radius: 5px;
  border: 2px dotted transparent;
  opacity: 0.9;
}

.info_footer .container .block_form .form #feedback-form input[type="submit"]:hover {
  background: #fff;
  color: #000;
  opacity: 1;
}

.info_footer .container .block_form .form #feedback-form .checkbox {
  padding-top: 10px;
}

.info_footer .container .block_form .form #feedback-form .checkbox span {
  color: #fff;
  font-family: 'Playfair Display', serif;
  font-size: 16px;
}

.info_footer .container .block_form .form #feedback-form .checkbox span a {
  color: #fff;
  margin-top: 5px;
  display: inline-block;
}

.info_footer .container .block_form .form #feedback-form span a {
  color: #333;
  transition: all 0.2s ease-in-out;
}

.info_footer .container .block_form .form #feedback-form span a:hover {
  color: #e5017c;
}

.info_footer .container .social_networks {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(50% - 30px);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.info_footer .container .social_networks .block .logo {
  padding-bottom: 20px;
  margin-top: -90px;
}

.info_footer .container .social_networks .block .logo img {
  width: 100%;
}

.info_footer .container .social_networks .block .text {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.info_footer .container .social_networks .block .text a {
  text-decoration: none;
  color: #e5017c;
  font-size: 18px;
  margin-bottom: 5px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.info_footer .container .social_networks .block .text p {
  color: #929292;
  font-size: 18px;
  padding-bottom: 5px;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.info_footer .container .social_networks .block .text h2 {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.3px;
  color: #fff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  margin-bottom: 5px;
}

.info_footer .container .social_networks .block .social_network {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.info_footer .container .social_networks .block .social_network a {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(top, #C7006B, #AD005E);
  box-shadow: 3px 3px 7px 0 rgba(229, 1, 124, 0.4), inset 0 -1px 0 0 #e5017c;
  border-bottom: 1px solid #e5017c;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  width: 33px;
  height: 33px;
  line-height: 0;
  border-radius: 50%;
  text-decoration: none;
  margin: 0 5px;
}

.info_footer .container .social_networks .block .social_network a .fa {
  color: #fff;
  line-height: 0;
  font-size: 16px;
}

.info_footer .container .social_networks .copywriting {
  position: absolute;
  bottom: -80px;
  right: 0;
}

.info_footer .container .social_networks .copywriting p {
  color: #fff;
  font-size: 16px;
  padding-right: 20px;
}

.info_privacy_policy {
  padding: 120px 0 120px 0;
}

.info_privacy_policy .caption h1 {
  font-size: 28px;
  line-height: 35px;
  font-weight: 700;
  text-transform: uppercase;
  color: #adadad;
  letter-spacing: 0.5px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.info_privacy_policy .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info_privacy_policy .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(100% - 30px);
}

.info_privacy_policy .container .block h2 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  color: #333;
  letter-spacing: 0.5px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  padding-top: 10px;
}

.info_privacy_policy .container .block p {
  color: #333;
  font-size: 19px;
  line-height: 26px;
  padding-bottom: 10px;
}

.info_privacy_policy .container .block p a {
  text-decoration: none;
  color: #e5017c;
  font-size: 19px;
}

.info_privacy_policy .container .block ul {
  padding-left: 20px;
}

.info_privacy_policy .container .block ul li {
  color: #333;
  font-size: 19px;
  line-height: 26px;
  list-style: none;
}

.info_privacy_policy .container .block .contacts {
  padding-top: 20px;
}

.info_privacy_policy .container .block .contacts p {
  padding-bottom: 5px;
}

.info_privacy_policy .container .block .contacts p span {
  font-weight: 700;
}

.info_privacy_policy .container .block .contacts p:nth-child(1) {
  padding-top: 10px;
  font-weight: 700;
  padding-bottom: 10px;
}

@media screen and (max-width: 1100px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .info_header .container {
    display: none;
  }

  .info_header .navbar-header {
    display: flex;
  }

  .info_premium_choice:before {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .info_block_video .container .titles .photo_logo img:nth-child(1) {
    width: 180px;
    margin-left: -50px;
  }

  .info_block_video .container .titles .photo_logo img:nth-child(3) {
    width: 140px;
    margin-left: -50px;
  }

  .info_block_video .container .titles .photo_logo .name {
    font-size: 14px;
  }

  .info_block_video .container .titles .photo_logo .text h4 {
    font-size: 20px;
    line-height: 26px;
    padding-top: 0;
  }

  .info_block_video .container .titles .photo_logo .text p {
    font-size: 14px;
  }

  .info_gift_nature .block h2 {
    font-size: 25px;
    line-height: 32px;
  }

  .info_natural_mineral_composition .container .block {
    width: calc(50% - 30px);
  }

  .info_why_jevea:before {
    display: none;
  }

  .info_why_jevea:after {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 450px;
  }

  .info_why_jevea .caption {
    padding-bottom: 50px;
  }

  .info_why_jevea .container .block {
    width: calc(100% - 30px);
  }

  .info_why_jevea .container .block .photo img {
    width: 50%;
  }

  .info_why_jevea .container .block ul li {
    font-size: 17px;
    line-height: 24px;
    padding: 7px 0 10px 0;
  }

  .info_jevea_signature_bottle .caption {
    padding-bottom: 40px;
  }

  .info_jevea_signature_bottle .caption img {
    width: 150px;
  }

  .info_jevea_signature_bottle .caption h2 {
    font-size: 28px;
  }

  .info_jevea_signature_bottle .container .block p {
    font-size: 17px;
    line-height: 20px;
    padding: 7px 0 10px 0;
  }

  .info_jevea_signature_bottle .container .block:nth-child(2) {
    order: -1;
    display: flex;
  }

  .info_jevea_signature_bottle .container .block:nth-child(2) .photo {
    text-align: center;
  }

  .info_jevea_signature_bottle .container .block:nth-child(2) .photo img {
    width: 50%;
  }

  .info_jevea_signature_bottle .container .block:nth-child(1) {
    width: calc(100% - 30px);
  }

  .info_jevea_signature_bottle .container .block:nth-child(2) {
    width: calc(100% - 30px);
  }

  .info_jevea_signature_bottle .container .block:nth-child(3) {
    width: calc(100% - 30px);
  }

  .info_instagram .caption {
    padding-bottom: 40px;
  }

  .info_instagram .caption img {
    width: 150px;
  }

  .info_instagram .caption h2 {
    font-size: 28px;
  }

  .info_instagram .container {
    justify-content: center;
  }

  .info_instagram .container .block {
    padding-bottom: 25px;
  }

  .info_instagram .container .block {
    width: calc(50% - 30px);
  }

  .info_slogn .block {
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
  }

  .info_slogn .block h2 {
    font-size: 25px;
    line-height: 32px;
  }

  .info_jevea_benefits:before {
    display: none;
  }

  .info_jevea_benefits .caption {
    padding-bottom: 40px;
  }

  .info_jevea_benefits .caption img {
    width: 150px;
  }

  .info_jevea_benefits .caption h2 {
    font-size: 28px;
  }

  .info_jevea_benefits .container .block p {
    font-size: 17px;
    line-height: 20px;
    padding: 7px 0 10px 0;
  }

  .info_jevea_benefits .container .block:nth-child(2) {
    order: -1;
    display: flex;
  }

  .info_jevea_benefits .container .block:nth-child(2) .photo {
    text-align: center;
    padding-top: 10px;
  }

  .info_jevea_benefits .container .block:nth-child(2) .photo img {
    width: 50%;
  }

  .info_jevea_benefits .container .block:nth-child(1) {
    width: calc(100% - 30px);
  }

  .info_jevea_benefits .container .block:nth-child(2) {
    width: calc(100% - 30px);
  }

  .info_jevea_benefits .container .block:nth-child(3) {
    width: calc(100% - 30px);
  }

  .info_time_honored .container .block .text h2 {
    font-size: 28px;
    line-height: 35px;
  }

  .info_sourse .caption {
    padding-bottom: 40px;
  }

  .info_sourse .caption img {
    width: 150px;
  }

  .info_sourse .caption h2 {
    font-size: 28px;
  }

  .info_sourse .container .block p {
    font-size: 17px;
    line-height: 20px;
  }

  .info_sourse .container .block .photo_left {
    padding-top: 10px;
  }

  .info_products:after {
    display: none;
  }

  .info_products .caption .title {
    padding-bottom: 40px;
  }

  .info_products .caption .title img {
    width: 150px;
  }

  .info_products .caption .title h2 {
    font-size: 28px;
  }

  .info_products .caption h3 {
    line-height: 20px;
  }

  .info_products .container .block {
    width: calc(100% - 30px);
  }

  .info_products .container .block .photo {
    text-align: center;
  }

  .info_products .container .block .photo img {
    width: 70%;
  }

  .info_products .container .block .text h2 {
    padding-top: 0;
  }

  .info_premium_choice .caption {
    padding-bottom: 40px;
  }

  .info_premium_choice .caption h2 {
    font-size: 28px;
  }

  .info_premium_choice .container .block .photo {
    text-align: center;
  }

  .info_premium_choice .container .block .photo img {
    width: 50%;
  }

  .info_premium_choice .container .block .photo img {
    width: 80%;
  }

  .info_awards_winning .caption {
    padding-bottom: 40px;
  }

  .info_awards_winning .caption img {
    width: 150px;
  }

  .info_awards_winning .caption h2 {
    font-size: 28px;
  }

  .info_awards_winning .container .block .photo {
    text-align: center;
    padding: 30px 0;
  }

  .info_awards_winning .container .block p {
    font-size: 17px;
    line-height: 20px;
  }

  .info_production .caption {
    padding-bottom: 40px;
  }

  .info_production .caption img {
    width: 150px;
  }

  .info_production .caption h2 {
    font-size: 28px;
  }

  .info_production .container .block p {
    font-size: 17px;
    line-height: 20px;
  }

  .info_production .container .block .photo_left {
    padding-top: 10px;
    text-align: center;
  }

  .info_production .container .block .photo_left img {
    width: 80%;
  }

  .info_production .container .block .photo_right {
    text-align: center;
  }

  .info_production .container .block .photo_right img {
    width: 80%;
  }

  .info_footer .caption {
    padding-bottom: 40px;
  }

  .info_footer .caption h3 {
    font-size: 28px;
  }

  .info_footer .container .block_form {
    width: calc(100% - 30px);
  }

  .info_footer .container .block_form .form #feedback-form .input {
    display: block;
  }

  .info_footer .container .block_form .form #feedback-form .input .inp {
    padding: 0 0 5px 0;
  }

  .info_footer .container .block_form .form h2 {
    font-size: 20px;
  }

  .info_footer .container .social_networks {
    width: calc(100% - 30px);
  }

  .info_footer .container .social_networks .block .logo {
    margin-top: 30px;
  }

  .info_privacy_policy {
    padding: 100px 0 120px 0;
  }

  .info_privacy_policy .caption h1 {
    font-size: 28px;
  }

  .info_privacy_policy .container .block p {
    font-size: 17px;
    line-height: 20px;
  }

  .info_privacy_policy .container .block h2 {
    font-size: 20px;
    line-height: 26px;
  }
}

@media screen and (max-width: 780px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .info_block_video #video-bg {
    display: none;
  }

  .info_block_video .container .titles .photo_water {
    width: 100%;
    text-align: center;
  }

  .info_block_video .container .titles .photo_water img {
    width: 30%;
  }

  .info_block_header_mob {
    display: block;
  }

  .info_block_header_mob .container .block {
    width: calc(50% - 30px);
  }

  .info_gift_nature .block h2 {
    font-size: 20px;
    line-height: 26px;
  }

  .info_natural_mineral_composition .caption {
    padding-bottom: 40px;
  }

  .info_natural_mineral_composition .caption h2 {
    font-size: 20px;
  }

  .info_natural_mineral_composition .container .block_img {
    width: calc(50% - 30px);
  }

  .info_why_jevea .container .block .photo img {
    width: 50%;
  }

  .info_why_jevea .container .block ul {
    padding: 0;
    margin: 0;
    padding-left: 10px;
  }

  .info_jevea_signature_bottle .caption {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 10px;
  }

  .info_jevea_signature_bottle .caption img {
    margin: 0 auto;
    width: 150px;
  }

  .info_jevea_signature_bottle .caption h2 {
    font-size: 28px;
  }

  .info_instagram .caption {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .info_instagram .caption img {
    margin: 0 auto;
    width: 150px;
  }

  .info_instagram .caption h2 {
    font-size: 28px;
  }

  .info_instagram .container .block {
    width: calc(100% - 30px);
  }

  .info_slogn .block h2 {
    font-size: 20px;
    line-height: 26px;
  }

  .info_jevea_benefits .caption {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 10px;
  }

  .info_jevea_benefits .caption img {
    margin: 0 auto;
    width: 150px;
  }

  .info_jevea_benefits .caption h2 {
    font-size: 28px;
  }

  .info_sourse .caption {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 10px;
  }

  .info_sourse .caption img {
    margin: 0 auto;
    width: 150px;
  }

  .info_sourse .caption h2 {
    font-size: 28px;
  }

  .info_sourse .container .block {
    width: calc(100% - 30px);
  }

  .info_products .caption .title {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 0;
  }

  .info_products .caption .title img {
    margin: 0 auto;
    width: 150px;
  }

  .info_products .caption .title h2 {
    font-size: 28px;
  }

  .info_products .caption h3 {
    padding: 0 30px 0 30px;
  }

  .info_products .container .block .photo {
    text-align: center;
  }

  .info_products .container .block .photo img {
    width: 80%;
  }

  .info_products .container .block .text h2 {
    padding-top: 0;
  }

  .info_products .container .block .text .liter p {
    padding: 0 40px;
    font-size: 17px;
  }

  .info_premium_choice .caption {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .info_premium_choice .caption h2 {
    font-size: 28px;
  }

  .info_premium_choice .container .block {
    width: calc(100% - 30px);
  }

  .info_awards_winning .caption {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 10px;
  }

  .info_awards_winning .caption img {
    margin: 0 auto;
    width: 150px;
  }

  .info_awards_winning .caption h2 {
    font-size: 28px;
  }

  .info_awards_winning .container .block {
    width: calc(100% - 30px);
  }

  .info_awards_winning .container .block .photo {
    flex-wrap: wrap;
  }

  .info_awards_winning .container .block .photo .bottle {
    width: 100%;
    padding-bottom: 40px;
  }

  .info_awards_winning .container .block .photo .bottle img {
    width: 40%;
  }

  .info_awards_winning .container .block .photo .medal {
    width: 100%;
  }

  .info_awards_winning .container .block .photo .medal img {
    width: 45%;
  }

  .info_awards_winning .container .block .photo .kremlin {
    width: 100%;
    padding-bottom: 30px;
  }

  .info_awards_winning .container .block .photo .kremlin img {
    width: 80%;
  }

  .info_awards_winning .container .block_mob {
    display: flex;
    flex-direction: column;
  }

  .info_awards_winning .container .block_mob .photo {
    order: -1;
  }

  .info_awards_winning .container .block_mob .photo .bottle {
    padding-bottom: 10px;
  }

  .info_production .caption {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 10px;
  }

  .info_production .caption img {
    margin: 0 auto;
    width: 150px;
  }

  .info_production .caption h2 {
    font-size: 28px;
  }

  .info_production .container .block {
    width: calc(100% - 30px);
  }

  .info_production .container .block .photo_left img {
    width: 100%;
  }

  .info_production .container .block .photo_right img {
    width: 100%;
  }

  .info_production .container .block:nth-child(2) {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
  }

  .info_production .container .block:nth-child(2) p {
    order: -1;
  }

  .info_footer .caption {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 10px;
  }

  .info_footer .caption h3 {
    font-size: 28px;
  }

  .info_footer .container .block_form .form #feedback-form .input {
    flex-wrap: wrap;
  }

  .info_footer .container .block_form .form #feedback-form .input input:nth-child(1) {
    margin-right: 0;
  }

  .info_footer .container .block_form .form #feedback-form .input input:nth-child(2) {
    margin-right: 0;
  }

  .info_privacy_policy .caption h1 {
    font-size: 28px;
  }
}

@media screen and (max-width: 650px) {
  .info_block_header_mob {
    padding-top: 60px;
  }

  .info_block_header_mob .container .block .name {
    padding: 0 0 10px 0;
    font-size: 15px;
  }

  .info_block_header_mob .container .block img:nth-child(2) {
    width: 100px;
  }

  .info_block_header_mob .container .block .text h4 {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .info_block_header_mob .container .block .text p {
    font-size: 13px;
    line-height: 19px;
  }
}

@media screen and (max-width: 560px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .info_block_header_mob {
    padding-top: 90px;
  }

  .info_block_header_mob .container .block {
    width: calc(100% - 30px);
  }

  .info_block_header_mob .container .block .bottle {
    padding-top: 30px;
    width: 50%;
  }

  .info_gift_nature .block {
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
  }

  .info_gift_nature .block h2 {
    font-size: 20px;
    line-height: 26px;
    padding: 0 30px;
  }

  .info_natural_mineral_composition .container .block {
    width: calc(100% - 30px);
  }

  .info_natural_mineral_composition .container .block_img {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .info_why_jevea .container .block .photo img {
    width: 80%;
  }

  .info_why_jevea .container .block ul li {
    font-size: 16px;
    line-height: 22px;
    padding: 5px 0 7px 0;
  }

  .info_jevea_signature_bottle .caption h2 {
    font-size: 23px;
    line-height: 27px;
  }

  .info_jevea_signature_bottle .container .block:nth-child(2) .photo img {
    width: 80%;
  }

  .info_instagram .caption h2 {
    font-size: 25px;
    line-height: 28px;
  }

  .info_instagram .container .block {
    padding-bottom: 15px;
  }

  .info_slogn .block h2 {
    font-size: 20px;
    line-height: 26px;
    padding: 0 30px;
  }

  .info_jevea_benefits .caption h2 {
    font-size: 23px;
    line-height: 27px;
  }

  .info_jevea_benefits .container .block:nth-child(2) .photo img {
    width: 80%;
  }

  .info_time_honored .container .block .text h2 {
    font-size: 24px;
    line-height: 26px;
  }

  .info_sourse .caption h2 {
    font-size: 23px;
    line-height: 27px;
  }

  .info_products .caption .title h2 {
    font-size: 23px;
    line-height: 27px;
  }

  .info_products .container .block .text .liter p {
    padding: 0 10px;
  }

  .info_premium_choice .caption {
    padding-bottom: 40px;
  }

  .info_premium_choice .caption h2 {
    font-size: 23px;
    line-height: 27px;
  }

  .info_awards_winning .caption h2 {
    font-size: 23px;
    line-height: 27px;
  }

  .info_production .caption h2 {
    font-size: 23px;
    line-height: 27px;
  }

  .info_footer .caption h3 {
    font-size: 23px;
    line-height: 27px;
  }

  .info_privacy_policy .caption h1 {
    font-size: 23px;
    line-height: 28px;
  }
}

@media screen and (max-width: 360px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}